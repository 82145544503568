import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Home from './components/Home';
import Resume from './components/Resume';
import Contato from './components/Contato';
import SobreMim from './components/SobreMim';
import './App.css';
import { Grid, Button } from "@mui/material";
import HomeIcon from '@mui/icons-material/Home';
import InfoIcon from '@mui/icons-material/Info';
import DescriptionIcon from '@mui/icons-material/Description';

function App() {
  useEffect(() => {
    // Inicializa as partículas
    if (window.particlesJS) {
      window.particlesJS('particles-js', {
        particles: {
          number: {
            value: 80,
            density: {
              enable: true,
              value_area: 800,
            },
          },
          color: { value: "#ffffff" },
          shape: { type: "circle" },
          opacity: { value: 0.5 },
          size: { value: 3 },
          move: {
            enable: true,
            speed: 2,
            direction: "none",
            random: false,
            straight: false,
            out_mode: "bounce",
            attract: { enable: false },
          },
          line_linked: {
            enable: true,
            distance: 150,
            color: "#ffffff",
            opacity: 0.4,
            width: 1,
          },
        },
        interactivity: {
          detect_on: "canvas",
          events: {
            onhover: { enable: true, mode: "grab" },
            onclick: { enable: true, mode: "push" },
          },
          modes: {
            grab: { distance: 200, line_linked: { opacity: 0.5 } },
            push: { particles_nb: 4 },
          },
        },
        retina_detect: true,
      });
    }
  }, []);

  return (
    <div style={{ position: "relative", overflow: "hidden", minHeight: "100vh" }}>
      {/* Div para o fundo das partículas */}
      <div
        id="particles-js"
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          zIndex: -1, // Mantém as partículas no fundo
        }}
      ></div>

      <Router>
        <header>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            spacing={2}
            sx={{ paddingTop: 2, paddingLeft: 10, paddingRight: 10 }}
          >
            {/* Logo */}
            <Grid item>
              <Link
                to="/"
                style={{
                  textDecoration: "none",
                  fontSize: "2rem",
                  fontWeight: "bold",
                  color: "#1976d2",
                  fontFamily: "'Playfair Display', serif",
                }}
              >
                Renato Yamanaka
              </Link>
            </Grid>

            {/* Navigation Links */}
            <Grid item>
              <nav>
                <Grid container spacing={4}>
                  <Grid item>
                    <Link
                      to="/"
                      style={{

                        textDecoration: "none",
                        fontSize: "1rem",
                        color: "#ffffff",
                        fontWeight: "500",
                        padding: "8px 12px",
                        borderRadius: "4px",
                        backgroundColor: "#1976d2",
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                        transition: "background-color 0.3s ease",
                      }}
                      onMouseOver={(e) => (e.target.style.backgroundColor = "#1565c0")}
                      onMouseOut={(e) => (e.target.style.backgroundColor = "#1976d2")}
                    >
                      <HomeIcon style={{ fontSize: "20px" }} />
                      INICIO
                    </Link>
                  </Grid>
                  <Grid item>
                    <Link
                      to="/sobre-mim"
                      style={{

                        textDecoration: "none",
                        fontSize: "1rem",
                        color: "#ffffff",
                        fontWeight: "500",
                        padding: "8px 12px",
                        borderRadius: "4px",
                        backgroundColor: "#1976d2",
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                        transition: "background-color 0.3s ease",
                      }}
                      onMouseOver={(e) => (e.target.style.backgroundColor = "#1565c0")}
                      onMouseOut={(e) => (e.target.style.backgroundColor = "#1976d2")}
                    >
                      <InfoIcon style={{ fontSize: "20px" }} />
                      SOBRE-MIM
                    </Link>
                  </Grid>
                  <Grid item>
                    <Link
                      to="/resume"
                      style={{

                        textDecoration: "none",
                        fontSize: "1rem",
                        color: "#ffffff",
                        fontWeight: "500",
                        padding: "8px 12px",
                        borderRadius: "4px",
                        backgroundColor: "#1976d2",
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                        transition: "background-color 0.3s ease",
                      }}
                      onMouseOver={(e) => (e.target.style.backgroundColor = "#1565c0")}
                      onMouseOut={(e) => (e.target.style.backgroundColor = "#1976d2")}
                    >
                      <DescriptionIcon style={{ fontSize: "20px" }} />
                      RESUMO
                    </Link>
                  </Grid>
                </Grid>
              </nav>
            </Grid>

            {/* Contact Button */}
            <Grid item>
            <Button
                variant="contained"
                color="primary"
                style={{
                  backgroundColor: "#1976d2",
                  color: "#ffffff",
                  fontWeight: "bold",
                  transition: "background-color 0.3s ease",
                }}
                onMouseOver={(e) => (e.target.style.backgroundColor = "#1565c0")}
                onMouseOut={(e) => (e.target.style.backgroundColor = "#1976d2")}
              >
                Entre em contato
              </Button>
            </Grid>
          </Grid>
        </header>

        <main className="main-content">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/resume" element={<Resume />} />
            <Route path="/sobre-mim" element={<SobreMim />} />
            <Route path="/contato" element={<Contato />} />
          </Routes>
        </main>

        <footer>
          Feito por Yamanaka
        </footer>
      </Router>
    </div>
  );
}

export default App;
