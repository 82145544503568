import React, { useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';


function StuffILike() {
  // Configurações do carrossel
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 10,
    slidesToScroll: 2,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      
    ],
  };

  useEffect(() => {
    // Seleciona todos os ícones do carrossel
    const icons = document.querySelectorAll('.slick-slide i');

    // Aplica o estilo dinamicamente
    icons.forEach((icon) => {
      icon.style.fontSize = '50px'; // Ajusta o tamanho do ícone
      icon.style.transition = 'transform 0.3s'; // Adiciona uma transição para suavidade
    });

    // Adiciona um evento de hover para o efeito de zoom
    icons.forEach((icon) => {
      icon.addEventListener('mouseover', () => {
        icon.style.transform = 'scale(1.2)';
      });
      icon.addEventListener('mouseout', () => {
        icon.style.transform = 'scale(1)';
      });
    });

    // Cleanup para remover os event listeners ao desmontar
    return () => {
      icons.forEach((icon) => {
        icon.removeEventListener('mouseover', () => {
          icon.style.transform = 'scale(1.2)';
        });
        icon.removeEventListener('mouseout', () => {
          icon.style.transform = 'scale(1)';
        });
      });
    };
  }, []); // Executa apenas uma vez, após o render inicial

  return (
    <section id="stuff-i-like" className="stuff-section grid-item">
      <div className="stuff-card">
        <h3>Minhas Skills</h3>
        <Slider
          {...settings}
          style={{
            margin: '0 auto',
            width: '93%',
          }}
>
          <div>
            <i className="devicon-react-original colored" title="React"></i>
          </div>
          <div>
            <i className="devicon-javascript-plain colored" title="JavaScript"></i>
          </div>
          <div>
            <i className="devicon-typescript-plain colored" title="TypeScript"></i>
          </div>
          <div>
            <i className="devicon-nextjs-original" title="Next.js"></i>
          </div>
          <div>
            <i className="devicon-html5-plain colored" title="HTML5"></i>
          </div>
          <div>
            <i className="devicon-css3-plain colored" title="CSS3"></i>
          </div>
          <div>
            <i className="devicon-bootstrap-plain colored" title="Bootstrap"></i>
          </div>
          <div>
            <i className="devicon-nodejs-plain colored" title="Node.js"></i>
          </div>
          <div>
            <i className="devicon-laravel-plain colored" title="Laravel"></i>
          </div>
          <div>
            <i className="devicon-python-plain colored" title="Python"></i>
          </div>
          <div>
            <i className="devicon-mongodb-plain colored" title="MongoDB"></i>
          </div>
          <div>
            <i className="devicon-mysql-plain colored" title="MySQL"></i>
          </div>
          <div>
            <i className="devicon-docker-plain colored" title="Docker"></i>
          </div>
          <div>
          <i className="devicon-amazonwebservices-plain colored" title="AWS"></i>
          </div>
        </Slider>
      </div>
      <br />
      <br />
    </section>
  );
}

export default StuffILike;
