import React from 'react';
import './Resume.css';

function Resume() {
  return (
    <section className="resume-page">
      <div className="resume-header">
        <h2>Experiência</h2>
      </div>

      <div className="experience-section">
        {/* Omnilink Tecnologia SA */}
        <div className="experience-item">
          <h3>Omnilink Tecnologia SA</h3>
          <h4>Analista de Sistemas FullStack / Analista de Dados Sênior</h4>
          <span>Dez 2020 - Atualmente</span>
          <p>Desenvolvimento da plataforma Portal Técnico e Meu Portal:</p>
          <ul>
            <li>Utilizando Node.js, Laravel, Java, C#, Python, API Rest, MySQL / SQL Server e PHP.</li>
            <li>Gestão de telecom (análise de faturas, chips, antenas satélite) para redução de custos via API e banco de dados com Python.</li>
            <li>Criação de dashboards no Excel, Power BI e Tableau com análise de dados para geração de insights.</li>
          </ul>
        </div>

        {/* PagSeguro UOL */}
        <div className="experience-item">
          <h3>PagSeguro UOL</h3>
          <h4>Analista de Sistemas Sênior</h4>
          <span>Mai 2015 - Mai 2019</span>
          <p>Desenvolvimento de integrações para aplicativos Mobile iOS e Android:</p>
          <ul>
            <li>Implementação de adaptações para sistemas de pagamento utilizando Kotlin, Swift e React Native.</li>
            <li>Integração de sistemas com bancos de dados PostgreSQL e serviços na AWS.</li>
          </ul>
        </div>

        {/* VirtuaTI */}
        <div className="experience-item">
          <h3>VirtuaTI</h3>
          <h4>Desenvolvedor C#</h4>
          <span>Jun 2009 - Nov 2020</span>
          <p>Desenvolvimento de aplicações desktop e gestão de bancos de dados:</p>
          <ul>
            <li>Utilização de C# e SQL Server para aplicações de servidores e banco de dados.</li>
          </ul>
        </div>
      </div>
    </section>
  );
}

export default Resume;
