import React from 'react';
import GitHub from './GitHub';
import SideQuests from './SideQuests';
import StuffILike from './StuffILike';
import { FaArrowRight } from 'react-icons/fa'; 

function Home() {
  return (
    <div className="grid-container">
      <section id="about" className="about-section grid-item" style={{ position: 'relative' }}>
        <div className="avatar"></div>
        <h2>Hey, Me chamo Renato 👋</h2>
        <p>Developer Software 💻</p>

        <div className="scroll-to-about" style={{ position: 'absolute', right: '20px', bottom: '20px' }}>
          <a href="/sobre-mim">
                <FaArrowRight size={32} />
          </a>
        </div>
      </section>

      <GitHub />
      <SideQuests />
      <StuffILike />
    </div>
  );
}

export default Home;
