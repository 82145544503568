import React from 'react';
import './SobreMim.css';

function SobreMim() {
  return (
    <div className="sobre-mim-container">
      <h1>Sobre Mim</h1>
      <p>
        Meu nome é Renato da Costa e Silva Yamanaka. Sou apaixonado por tecnologia, análise de dados e desenvolvimento de software, 
        sempre buscando soluções inovadoras para problemas complexos. Minha trajetória profissional tem sido marcada por uma evolução contínua 
        em diversas áreas, incluindo sistemas full-stack, análise de dados e gestão de telecom.
      </p>
      <h2>Minha Formação</h2>
      <ul>
        <li>
          <strong>Graduação em Analise de Sistemas de Desenvolvimento de Software</strong> - Universidade Estácio
        </li>
        <li>
          <strong>Graduação em Rede de Computadores</strong> - Universidade Cruzeiro do Sul
        </li>
        <li>
          <strong>Pós-Graduação em Análise de Dados</strong> - Universidade ABC
        </li>
      </ul>
      <h2>Minhas Habilidades</h2>
      <ul>
        <li>Desenvolvimento Full-Stack (Node.js, Laravel, C#, Python, PHP)</li>
        <li>Banco de Dados (MySQL, SQL Server, PostgreSQL)</li>
        <li>APIs RESTful e Integrações de Sistemas</li>
        <li>Análise de Dados (Python, Power BI, Tableau)</li>
        <li>Gestão de Telecom (Redução de custos com análise de faturas e contratos)</li>
      </ul>
      <h2>Meu Objetivo</h2>
      <p>
        Estou sempre buscando desafios que permitam alavancar minha experiência e habilidades em tecnologia, análise de dados e desenvolvimento de software. 
        Meu objetivo é contribuir significativamente para a transformação digital de organizações, promovendo eficiência, inovação e resultados de impacto.
      </p>
      <p>
        Se quiser saber mais sobre meu trabalho ou discutir projetos, sinta-se à vontade para entrar em contato.
      </p>
    </div>
  );
}

export default SobreMim;
