import React from 'react';
import './Contato.css';

function Contato() {
  return (
    <div className="contato-container">
      <div className="contato-form">
        <h1>Entre em Contato</h1>
        <p>Eu adoraria ouvir suas ideias e pensamentos!</p>
        <p>Ou, se preferir, você pode me enviar um e-mail em:</p>
        <p><a href="mailto:renato@example.com">renato@example.com</a></p>
        
        <form>
          <input type="text" placeholder="Nome" name="name" required />
          <input type="email" placeholder="E-mail" name="email" required />
          <textarea placeholder="Mensagem" name="message" required></textarea>
          <button type="submit">ENVIAR</button>
        </form>
      </div>
    </div>
  );
}

export default Contato;
