import React from 'react';
import { FaArrowRight } from 'react-icons/fa'; 

function GitHub() {
  return (
    <section id="github" className="github-section grid-item" style={{ position: 'relative' }}>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <i className="devicon-github-original github-icon"></i>
      <br />
      <br />
      <h3>GitHub</h3>
      <br />
      <br />
      <br />
      <br />
      <div className="scroll-to-about" style={{ position: 'absolute', right: '20px', bottom: '20px' }}>
        <a href="https://github.com/" target="_blank" rel="noopener noreferrer">
          <FaArrowRight size={32}  />
        </a>
      </div>
    </section>
  );
}

export default GitHub;