import React from 'react';
import { FaLinkedin } from 'react-icons/fa'; // Importa o ícone do LinkedIn
import { FaArrowRight } from 'react-icons/fa'; 

function LinkedInCard() {
  return (
    <section id="github" className="github-section grid-item" style={{ position: 'relative' }}>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />

      <i className="github-icon"><FaLinkedin size={80} /></i>
        
      <h3>LinkedIn</h3>
      <br />
      <br />
      <br />
      <div className="scroll-to-about" style={{ position: 'absolute', right: '20px', bottom: '20px' }}>
        <a href="https://www.linkedin.com/in/renatoyamanaka/" target="_blank" rel="noopener noreferrer">
        <FaArrowRight size={32} />
        </a>
      </div>
    </section>
  );
}

export default LinkedInCard;
